angular.module('enervexSalesappApp').controller('JointCtrl', function($scope, $uibModalInstance, _, Auto, setValue, paint, ApplianceModel, ApplianceCompany, Util, checkPoint) {
	var checkObj = null,
		enforcePrecision = true;
	var prevWatch = null, 
		prevWatch2;

	$scope.supportECM = true;

	$scope.selectEconomizer = function() {
		// Util.updateEconomizer($scope.design, $scope.economizers)
		var economizer = Util.getSelectedEconmizer($scope.joint.ECM.economizer, $scope.economizers)
		if (economizer) {
			if (!$scope.joint.ECM) {
				$scope.joint.ECM = {}
			}
			$scope.joint.ECM.KValue = economizer.kValue;
			$scope.joint.ECM.MaxT = economizer.maxTempOut;
			$scope.joint.ECM.Dia = economizer.centerPointA.dim1;
		}
	}
	function load(joints) {
		$scope.selectedJoints = joints;

		//TODO filter on the available fittings instead of allowing the world
		$scope.multiFits = $scope.fittings;
		var payload = {
			ECM: {}
		}
		var first = $scope.selectedJoints[0];
		var jointKeys = ["Fit1", "ECM", "ID"]
		_.each(_.keys(first), function(key){
			if (!_.contains(jointKeys, key)){
				return
			}
			var value = first[key];
			var diff = _.find($scope.selectedJoints, function(o){
				return JSON.stringify(o[key]) != JSON.stringify(first[key])
			})
			if (!diff){
				if (key == "ECM") {
					payload[key] = _.clone(value)
				} else {
					if (value) {
						payload[key] = value
					}
				}
			}
		})
		if (!payload.ECM){
			payload.ECM = {}
		}
		// payload.ECM.economizer = Util.getSelectedEconmizer($scope.design.economizer, $scope.economizers)
		payload.ECM.economizer = $scope.design.economizer

		if (prevWatch) {
			prevWatch()
		}
		if (prevWatch2 && $scope.supportECM) {
			prevWatch2()
		}
		$scope.joint = payload;
		console.log("loaded ", payload)
		checkObj = {};
		prevWatch = $scope.$watchCollection("joint", function(newObj, oldObj){
			_.each(_.keys(newObj), function(key) {
				var newVal = newObj[key],
					oldVal = oldObj[key];
				if (newVal != oldVal){
					if (key == "ID" || newObj.ID != payload.ID){
						console.log("ingnoring "+key)
						return
					}
					console.log("set"+key)
					checkObj[key] = true;
				}
			})
		})
		if ($scope.supportECM){
			prevWatch2 = $scope.$watchCollection("joint.ECM", function(newObj, oldObj){
				_.each(_.keys(newObj), function(key) {
					var newVal = newObj[key],
						oldVal = oldObj[key];
					if (newVal != oldVal){
						checkObj.ECM = true;
						console.log("set ECM")
						// if (key == "ID" || newObj.ID != payload.ID){
						// 	console.log("ingnoring "+key)
						// 	return
						// }
						// console.log("set"+key)
						// checkObj[key] = true;
					}
				})
			})
		}
	}
	load($scope.selectionManager.selectedJoints())
	$scope.applyJoint = function() {
		_setProps();
		$scope._validate();
		paint()
	}
	function _setProps() {
		_.each($scope.selectedJoints, function(v) {
			if (checkObj.Fit1){
				if ($scope.joint.Fit1){
					var prop = Util.Ais1(v, $scope.design) ? "Fit1" : "Fit2";
					console.log("setting " + prop + " to",$scope.joint.Fit1)
					setValue(prop, $scope.joint.Fit1, "str",  v)
				}
			}
			if ($scope.supportECM && checkObj.ECM){
				var isEcm = ($scope.joint.Fit1 == 'ECM' || $scope.joint.Fit1 == 'ECO');
				if (isEcm){
					if (isNaN($scope.joint.ECM.KValue) || $scope.joint.ECM.KValue <= 0) {
						throw "Please enter a valid K-Value";
					}
					if (isNaN($scope.joint.ECM.MaxT) || $scope.joint.ECM.MaxT <= 0) {
						throw "Please enter a valid max. temperature."
					}
					if (isNaN($scope.joint.ECM.Dia) || $scope.joint.ECM.Dia <= 0) {
						throw "Please enter a valid diameter."
					}
					//make sure this is set before setValue is called
					Util.updateEconomizerValue($scope.design, $scope.joint.ECM)
				}
			}
		})
	}
	$scope.okJoint = function() {
		$scope.applyJoint();
		$scope._toSelectMode()
		var modalInstance = $uibModalInstance.close();
		paint();
	}
	$scope.canNext = function() {
		if (!$scope.selectedJoints) {
			return false
		} else if ($scope.selectedJoints.length != 1){
			return false;
		} else {
			var _next = $scope.selectionManager.nextJoint();
			return (_next.length == 1)
		}
	}
	$scope.next = function() {
		$scope.applyJoint()
		var _next = $scope.selectionManager.nextJoint();
		if (_next.length == 1) {
			$scope.selectionManager.unselectAll()
			$scope.selectionManager.selectJoint(_next[0])
			load(_next)
			paint()
		} else if (_next.length == 0){
			alert("you are at the end")
		} else {
			alert("too many next paths")
		}
	}
	$scope.canPrevious = function() {
		if (!$scope.selectedJoints) {
			return false
		} else if ($scope.selectedJoints.length != 1){
			return false;
		} else {
			var _previous = $scope.selectionManager.previousJoint();
			return (_previous.length == 1)
		}
	}
	$scope.previous = function() {
		$scope.applyJoint()
		var _previous = $scope.selectionManager.previousJoint();
		if (_previous.length == 1) {
			$scope.selectionManager.unselectAll()
			$scope.selectionManager.selectJoint(_previous[0])
			load(_previous);
			paint();
		} else if (_previous.length == 0){
			alert("you are at the begining")
		} else {
			alert("too many prvious paths")
		}
	}	
});
